import React from "react";
import { UserProvider } from "./context/UserContext";
import AllRoutes from "./routes/route";

const App = () => (
	<UserProvider>
		<AllRoutes />
	</UserProvider>
);

export default App;
