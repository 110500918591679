import { Form, Spinner, Table, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EXAMS_ORDERS_STATUS from "../../../../utils/exams-orders-status";
import { format, parseISO } from "date-fns";
import { BsEyeFill } from "react-icons/bs";
import { FaClinicMedical } from "react-icons/fa";
import {
  Card,
  InfoContainer,
  Title,
  Information,
  VerticalLine,
  AdditionalText,
  AdditionalInfo,
  InfoFinalContainer,
  SubInformation,
} from "../Styles/CustomCardExamList";
const ExamListComponentMobile = ({ examOrdersData, loadExamsInfo }) => {
  const navigate = useNavigate();
  return (
    <div>
      {examOrdersData?.data?.map((examOrder, index) => (
        <div className="mt-4">
          {" "}
          <Card
            onClick={() =>
              navigate(`/pedidos/${examOrder.id}/detalhes`, {
                state: { examDetails: examOrder },
              })
            }
            color={
              examOrder?.status == EXAMS_ORDERS_STATUS.CONCLUDED
                ? "#4caf50"
                : "#0d6efd"
            }
          >
            <InfoContainer>
              <Title
                color={
                  examOrder?.status == EXAMS_ORDERS_STATUS.CONCLUDED
                    ? "#4caf50"
                    : "#0d6efd"
                }
              >
                {examOrder?.status == EXAMS_ORDERS_STATUS.CONCLUDED
                  ? "Concluído"
                  : "Em Andamento"}
              </Title>
              <SubInformation>Local da Coleta:</SubInformation>
              <Information>{examOrder?.unit?.name}</Information>
              <SubInformation>Exames:</SubInformation>
              <Information>
                {loadExamsInfo(examOrder.items, examOrder?.status)}
              </Information>
              {/* <SubInformation>Médico Solicitante:</SubInformation>
          <Information>Centro goiânia</Information> */}
            </InfoContainer>
            <InfoFinalContainer>
              {" "}
              <VerticalLine />
              <AdditionalInfo>
                Emissão{" "}
                <div>
                  {format(parseISO(examOrder.created_at), "dd/MM/yyyy")}
                </div>{" "}
                <div align="center" className="mt-1">
                  <FaClinicMedical size={30} />
                </div>{" "}
              </AdditionalInfo>
            </InfoFinalContainer>
          </Card>
        </div>
      ))}
    </div>
    // <Table bordered responsive id="all-exams" className="table-mobile">
    //   <thead>
    //     <tr>
    //       <th>Detalhes</th>
    //       <th>Exames</th>
    //       <th>Situação</th>
    //       <th>Data do Pedido</th>
    //       <th>Unidade</th>
    //       <th>Pedido</th>

    //     </tr>
    //   </thead>
    //   <tbody>
    //     {examOrdersData.data.map((examOrder, index) => (
    //       <tr
    //         style={{ cursor: "pointer" }}
    //         data-index={index}
    //         onClick={() =>
    //           navigate(`/pedidos/${examOrder.id}/detalhes`, {
    //             state: { examDetails: examOrder },
    //           })
    //         }
    //       >
    //         <td className="text-center">
    //           <BsEyeFill
    //             size={25}
    //             style={{ cursor: "pointer", color: "#0d6efd" }}
    //           />{" "}
    //         </td>
    //         <td>{loadExamsInfo(examOrder.items)}</td>
    //         <td>
    //           {" "}
    //           <Badge
    //             className={
    //               examOrder?.status == EXAMS_ORDERS_STATUS.IN_PROGRESS
    //                 ? "bg-secondary"
    //                 : "bg-primary"
    //             }
    //           >
    //             {examOrder.status_name}
    //           </Badge>
    //         </td>
    //         <td>{format(parseISO(examOrder.created_at), "dd/MM/yyyy")}</td>
    //         <td>{examOrder.unit.name}</td>
    //         <td>{examOrder.id}</td>
    //       </tr>
    //     ))}
    //   </tbody>
    // </Table>
  );
};

export default ExamListComponentMobile;
