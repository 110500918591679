import axios from "axios";
import { InvalidCredentialsError } from "../errors/InvalidCredentialsError";
import { ServerError } from "../errors/ServerError";
import { getHeader } from "./servicesConfig";

export const login = async (user, password, accessType) => {
	try {
		const data = {
			username: user,
			password,
			accessType,
		};

		const result = await axios.post(
			process.env.REACT_APP_SERVER_URL + "/login",
			data
		);

		return result.data;
	} catch (e) {
		console.log(e.response.status);
		if (e && e.response && e.response.status) {
			switch (e.response.status) {
				case 401:
					throw new InvalidCredentialsError();
				case 404:
					throw new InvalidCredentialsError();
			}
		}
		throw new ServerError();
	}
};

export const forgotPasswordRequest = async (username) => {
	const data = {
		username,
	};

	const result = await axios.post(
		process.env.REACT_APP_SERVER_URL + "/request-password-recovery",
		data
	);

	return result.data;
};

export const changePasswordRequest = async (password, token) => {
	if (!password.length || !token.length)
		throw new Error("Senha e link inválidos.");

	const data = {
		token,
		password,
	};

	const result = await axios.post(
		process.env.REACT_APP_SERVER_URL + "/submit-password-recovery",
		data
	);

	return result.data;
};

export const logout = () => {
	localStorage.removeItem("user");
};

export const getUserData = () => {
	const localStorage = localStorage.getItem("user");

	const userInfo = JSON.parse(localStorage);

	return userInfo.token;
};

export const storeUserData = (userData) => {
	localStorage.setItem("user", userData);
};
