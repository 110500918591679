import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { Spinner, Table, Badge } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsDownload } from "react-icons/bs";
import "../../../../assets/sass/ExamDetails.scss";
import EXAMS_ORDERS_STATUS from "../../../../utils/exams-orders-status";
const ExamDetailsComponent = ({
  orderInfo,
  canDownloadOrder,
  downloadingOrderPdf,
  downloadOrder,
  ExamInfo,
}) => {
  return (
    <>
      <div className="exam-detail">
        <div style={{ flex: 1, display: "flex" }}>
          <div id="exam-data">
            <div>Pedido: </div>
            <div>Coleta: </div>
            {orderInfo?.items[0]?.request_professional_name ? (
              <div>Médico: </div>
            ) : (
              ""
            )}
            <div>Situação: </div>
          </div>

          <div id="exam-info">
            <div> {orderInfo.id}</div>
            <div> {format(parseISO(orderInfo?.created_at), "dd/MM/yyyy")}</div>
            {orderInfo?.items[0]?.request_professional_name ? (
              <div> {orderInfo.items[0].request_professional_name}</div>
            ) : (
              ""
            )}
            <div>
              {" "}
              <Badge className={canDownloadOrder ? "bg-success" : "bg-primary"}>
                {orderInfo?.status_name}
              </Badge>
            </div>
          </div>
        </div>

        <div id="exam-complete" style={{ marginTop: 20, textAlign: "center" }}>
          <div>
            <strong>
              {canDownloadOrder
                ? "Resultado Completo"
                : orderInfo.items?.find(
                    (e) => e?.status == EXAMS_ORDERS_STATUS.CONCLUDED
                  )
                ? "Resultado Parcial"
                : "Resultado"}{" "}
            </strong>
          </div>
          <div className="text-center mt-2">
            {downloadingOrderPdf ? (
              <Spinner
                animation="border"
                variant="primary"
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            ) : canDownloadOrder ? (
              <Button variant="success" onClick={downloadOrder}>
                {" "}
                <BsDownload />
                <span style={{ marginLeft: 5, color: "white" }}>Baixar</span>
              </Button>
            ) : orderInfo.items?.find(
                (e) => e?.status == EXAMS_ORDERS_STATUS.CONCLUDED
              ) ? (
              <Button variant="primary" onClick={downloadOrder}>
                {" "}
                <BsDownload />
                <span style={{ marginLeft: 5, color: "white" }}>Baixar</span>
              </Button>
            ) : (
              <span>Não disponível</span>
            )}
          </div>
        </div>
      </div>
      <Table bordered responsive id="all-exams">
        <thead>
          <tr>
            <th className="">Código</th>
            <th>Exame</th>
            <th>Data do Resultado</th>
            <th>Situação</th>
            <th className="">PDF Resultado</th>
          </tr>
        </thead>
        <tbody>
          {orderInfo?.items?.map((data, index) => (
            <ExamInfo exam={data} key={index} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ExamDetailsComponent;
