import { format, parseISO } from "date-fns";
import React, { useState } from "react";
//
import Button from "react-bootstrap/Button";
import { BiLinkExternal } from "react-icons/bi";
import { BsCheckLg } from "react-icons/bs";
import { AiOutlineFieldTime } from "react-icons/ai";
import "../../../../assets/sass/ExamDetails.scss";
import {
  Card,
  InfoContainer,
  Title,
  Information,
  SubInformation,
  TitleExamsResult,
  AccordionContainer,
  CardHeader,
  CardContent,
  Table,
  TableRow,
  TableLabel,
  DownloadButton,
} from "../../Components/Styles/CustomCardExamDetails";
import { getExamDownloadUrl } from "../../../../services/examsorderservice";
import EXAMS_ORDERS_STATUS from "../../../../utils/exams-orders-status";
import { Spinner } from "react-bootstrap";

const ExamDetailsComponentMobile = ({
  orderInfo,
  canDownloadOrder,
  downloadingOrderPdf,
  downloadOrder,
  ExamInfo,
}) => {
  const [expanded, setExpanded] = useState([]);

  const toggleAccordion = (index) => {
    const expandedCopy = [...expanded];
    expandedCopy[index] = !expandedCopy[index];
    setExpanded(expandedCopy);
  };
  async function download(exam) {
    try {
      const url = await getExamDownloadUrl(orderInfo.id, exam.id);

      if (!url) {
        throw new Error("URL nao encontrada");
      }

      window.open(url, "_blank");
    } catch (e) {
      console.log(e);
      throw new Error("URL nao encontrada");
    }
  }
  return (
    <>
      <Card
        color={
          orderInfo.status == EXAMS_ORDERS_STATUS.CONCLUDED
            ? "#4caf50"
            : "#0d6efd"
        }
      >
        <InfoContainer>
          <SubInformation>Local</SubInformation>
          <Information>{orderInfo?.unit?.name}</Information>
          <SubInformation>Tipo de exame</SubInformation>
          <Information>Exame Laboratorial</Information>
          <SubInformation>Data</SubInformation>
          <Information>
            {format(parseISO(orderInfo.created_at), "dd/MM/yyyy")}
          </Information>
          {orderInfo?.items[0]?.request_professional_name ? (
            <>
              <SubInformation>Solicitante</SubInformation>
              <Information>
                {orderInfo?.items[0]?.request_professional_name}
              </Information>
            </>
          ) : (
            ""
          )}
        </InfoContainer>
      </Card>
      <TitleExamsResult
        color={
          orderInfo.status == EXAMS_ORDERS_STATUS.CONCLUDED
            ? "#4caf50"
            : "#0d6efd"
        }
      >
        Resultados
      </TitleExamsResult>
      <AccordionContainer className="mt-3">
        {orderInfo?.items?.map((exam, index) => (
          <div key={index} className="mb-1">
            <CardHeader onClick={() => toggleAccordion(index)}>
              <div style={{ fontWeight: "bold" }}>
                {exam?.item?.name?.replace("- EXAMES LABORATORIAIS", "")}
              </div>
              <div style={{ fontWeight: "bold" }}>
                {EXAMS_ORDERS_STATUS.CONCLUDED == exam.status ? (
                  <BsCheckLg
                    size={20}
                    style={{ position: "relative", top: "-1px" }}
                    color="#4caf50"
                  />
                ) : (
                  <AiOutlineFieldTime
                    size={28}
                    style={{ position: "relative", top: "-1px" }}
                    color="#0d6efd"
                  />
                )}
              </div>
            </CardHeader>
            {expanded[index] && (
              <CardContent>
                {" "}
                <Table>
                  <TableRow>
                    <TableLabel>Situação:</TableLabel>
                    <div
                      style={{ fontWeight: "bold" }}
                      className={
                        exam?.status == EXAMS_ORDERS_STATUS.IN_PROGRESS
                          ? "text-primary"
                          : "text-success"
                      }
                    >
                      {" "}
                      <small style={{ position: "relative", top: "0.5px" }}>
                        {exam?.status_name}
                      </small>
                    </div>
                  </TableRow>
                  <TableRow>
                    <TableLabel>PDF Resultado:</TableLabel>
                    {exam.result_path ? (
                      <div
                        onClick={() => {
                          if (EXAMS_ORDERS_STATUS.CONCLUDED == exam.status) {
                            download(exam);
                          }
                        }}
                        style={{
                          color:
                            EXAMS_ORDERS_STATUS.CONCLUDED == exam?.status
                              ? "blue"
                              : "",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        {EXAMS_ORDERS_STATUS.CONCLUDED == exam?.status
                          ? "Baixar "
                          : "Não disponível"}{" "}
                        {EXAMS_ORDERS_STATUS.CONCLUDED == exam?.status ? (
                          <BiLinkExternal />
                        ) : (
                          ""
                        )}{" "}
                      </div>
                    ) : null}
                  </TableRow>
                  <TableRow>
                    <TableLabel>Data do Resultado:</TableLabel>
                    <div>
                      {exam?.result_at
                        ? format(parseISO(exam?.result_at), "dd/MM/yyyy")
                        : "Não disponível"}
                    </div>
                  </TableRow>
                  <TableRow>
                    <TableLabel>Código:</TableLabel>
                    <div>{exam?.id}</div>
                  </TableRow>
                  {/* Adicione mais linhas da tabela aqui, se necessário */}
                </Table>
              </CardContent>
            )}
          </div>
        ))}
      </AccordionContainer>

      {EXAMS_ORDERS_STATUS?.CONCLUDED == orderInfo?.status ? (
        <DownloadButton
          color="#4caf50"
          onClick={() => {
            downloadOrder();
          }}
        >
          {downloadingOrderPdf ? "Processando..." : "Baixar todos os exames"}
        </DownloadButton>
      ) : orderInfo?.items?.find(
          (e) => e.status == EXAMS_ORDERS_STATUS.CONCLUDED
        ) ? (
        <DownloadButton
          color="#0d6efd"
          onClick={() => {
            downloadOrder();
          }}
        >
          {downloadingOrderPdf ? "Processando..." : "Baixar resultado parcial"}
        </DownloadButton>
      ) : (
        ""
      )}
    </>
  );
};

export default ExamDetailsComponentMobile;
