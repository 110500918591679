import React from "react";
import { Form } from "react-bootstrap";

import "../assets/sass/ForgetPassword.scss";

function ForgetPasswordEmail() {
	return (
		<div className="login-page">
			<div className="login-box">
				<div className="illustration-wrapper">
					<img
						id="photo"
						src="https://www.reshot.com/preview-assets/illustrations/SUBYW3HTFQ/secure-credit-card-SUBYW3HTFQ-w1600-07817.jpg"
						alt="Login"
					/>
				</div>

				<Form name="forget-password" id="forget-password">
					<div>
						<p className="form-title">
							Clínica Vittá
							{/* <Link id="arrow-back" to="/">
								<Button variant="secundary">
									<BsArrowReturnLeft />{" "}
								</Button>
							</Link> */}
						</p>
						<div className="mt-3">
							<small>
								Foi enviado um e-mail de recuperação de senha. Verifique a sua
								caixa de entrada e spam. Caso não você ainda não tenha cadastro,
								nenhuma ação será realizada.
							</small>
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
}

export default ForgetPasswordEmail;
