import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { Spinner, Table, Badge } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsArrowReturnLeft, BsDownload, BsArrowLeft } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";

import "../../assets/sass/ExamDetails.scss";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { ExamOrderStatus } from "../../domain/examOrderStatus";
import { ToastyError } from "../../helpers/alerts";
import {
  getExamDownloadUrl,
  getOrderDownloadUrl,
} from "../../services/examsorderservice";
import EXAMS_ORDERS_STATUS from "../../utils/exams-orders-status";
import ExamDetailsComponent from "./Components/ExamDetailsComponents/ExamDetailsComponent";
import ExamDetailsComponentMobile from "./Components/ExamDetailsComponents/ExamDetailsComponentMobile";
import "../../assets/sass/ExamDetails.scss";

function ExamDetails() {
  const location = useLocation();

  const [downloadingOrderPdf, setDownloadingOrderPdf] = useState(false);

  let orderInfo = location.state.examDetails;

  // Variaveis do Toast
  const [show, setShow] = useState(false);
  const [errorText, setErrorText] = useState("");

  if (!orderInfo)
    return <span>Nenhuma informação deste exame foi encontrada.</span>;

  const canDownloadOrder = orderInfo.status === EXAMS_ORDERS_STATUS.CONCLUDED;

  async function downloadOrder() {
    setDownloadingOrderPdf(true);
    try {
      const url = await getOrderDownloadUrl(orderInfo.id);
      window.open(url, "_blank");
      setDownloadingOrderPdf(false);
    } catch (e) {
      console.error(e);
      setShow(true);
      setTimeout(() => setShow(false), 5000);
      setErrorText(
        "Ocorreu um erro ao tentar realizar o download deste pedido."
      );
      setDownloadingOrderPdf(false);
    }
  }

  function ExamInfo(props) {
    const exam = props.exam;
    const index = props.key;
  
    const [isDownloadingExam, setDownloadingExam] = useState(false);

    const shouldShowExamOrderPrediction =
      exam.status && exam.status === EXAMS_ORDERS_STATUS.IN_PROGRESS;
    const shouldShowDownloadButton =
      exam.status && exam.status === EXAMS_ORDERS_STATUS.CONCLUDED;

    async function download() {
      try {
        setDownloadingExam(true);

        const url = await getExamDownloadUrl(orderInfo.id, exam.id);

        if (!url) {
          throw new Error("URL nao encontrada");
        }

        window.open(url, "_blank");
        setDownloadingExam(false);
      } catch (e) {
        setShow(true);
        setTimeout(() => setShow(false), 5000);
        setErrorText(
          "Ocorreu um erro ao tentar realizar o download deste exame."
        );
        setDownloadingExam(false);
      }
    }

    return (
      <>
        {props?.showMobile ? (
          <tr data-index={index}>
             
            <td className="text-center">
              {isDownloadingExam ? (
                <Spinner
                  animation="border"
                  variant="info"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              ) : shouldShowDownloadButton ? (
                <Button
                  onClick={download}
                  variant="primary"
                  style={{ position: "relative", left: "-6px" }}
                >
                  <BsDownload color="white" size={20} />
                </Button>
              ) : (
                <>Não disponível</>
              )}
            </td>
            <td>{exam.item.name}</td>
            <td>
              {" "}
              <Badge
                className={
                  shouldShowDownloadButton ? "bg-success" : "bg-primary"
                }
              >
                {exam.status_name}
              </Badge>{" "}
            </td>
         
            <td>
              {exam?.result_at
                ? format(parseISO(exam.created_at), "dd/MM/yyyy")
                : "Não disponível"}
            </td>
            <td className="">{exam.id}</td>
          </tr>
        ) : (
          <tr data-index={index}>
            <td className="">{exam.id}</td>
            <td>{exam?.item?.name?.replace("- EXAMES LABORATORIAIS", "")}</td>
            <td>
              {exam?.result_at
                ? format(parseISO(exam.created_at), "dd/MM/yyyy")
                : "Não disponível"}
            </td>
            <td>
              {" "}
              <Badge
                className={
                  shouldShowDownloadButton ? "bg-success" : "bg-primary"
                }
              >
                {exam.status_name}
              </Badge>{" "}
            </td>
            {exam.result_path ?   <td className="text-center">
              {isDownloadingExam ? (
                <Spinner
                  animation="border"
                  variant="info"
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              ) : shouldShowDownloadButton ? (
                <Button
                  onClick={download}
                  style={{ position: "relative", left: "-6px" }}
                  variant="success"
                >
                  <BsDownload color="white" size={20} />
                </Button>
              ) : (
                <>Não disponível</>
              )}
            </td> : <td></td>}

          </tr>
        )}
      </>
    );
  }

  return (
    <>
      <NavBar></NavBar>
      <ToastyError show={show} errorText={errorText} />
      <SideBar></SideBar>
      <div className="details-page">
        <div id="exam-list">
          <div className="d-flex justify-content-between mb-4">
            <h5 className="above-mobile">Detalhes do Pedido</h5>
            <h5
              className="show-mobile"
              style={{
                position: "relative",
                top: "6px",
                fontWeight: "bold",
                color: canDownloadOrder ? "#4caf50" : "#0d6efd",
              }}
            >
              Detalhes do Pedido
            </h5>
            <br />

            <div>
              <Link
                to="/pedidos"
                style={{
                  color: canDownloadOrder ? "green" : "#0195d5",
                  fontWeight: "bold",
                  textDecoration: "none",
                  transition: "text-decoration 0.3s ease",
                }}
                onMouseOver={(e) => {
                  e.target.style.textDecoration = "underline";
                }}
                onMouseOut={(e) => {
                  e.target.style.textDecoration = "none";
                }}
              >
                {" "}
                <BsArrowLeft fontSize={20} />{" "}
                <span className="above-mobile"> Voltar</span>
              </Link>
            </div>
          </div>
          <div className="above-mobile">
            <ExamDetailsComponent
              ExamInfo={ExamInfo}
              orderInfo={orderInfo}
              downloadOrder={downloadOrder}
              canDownloadOrder={canDownloadOrder}
              downloadingOrderPdf={downloadingOrderPdf}
            />
          </div>
          <div className="show-mobile">
            <ExamDetailsComponentMobile
              ExamInfo={ExamInfo}
              orderInfo={orderInfo}
              downloadOrder={downloadOrder}
              canDownloadOrder={canDownloadOrder}
              downloadingOrderPdf={downloadingOrderPdf}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ExamDetails;
