import axios from "axios";
import { ForbiddenError } from "../errors/ForbiddenError";
import { getHeader } from "./servicesConfig";

export const fetchExamOrders = async (params) => {
	try {
		const result = await axios.get(
			process.env.REACT_APP_SERVER_URL + "/exam-orders",
			{
				params: params,
				headers: getHeader(),
			}
		);

		return result.data;
	} catch (e) {
		if (e && e.response && e.response.status) {
			const status = e.response.status;

			if (status === 403) {
				throw new ForbiddenError();
			}
		}

		throw new Error();
	}
};

export const getOrderDownloadUrl = async (order_id) => {
	if (!order_id) throw new Error("could not find order id");
	const result = await axios.get(
		process.env.REACT_APP_SERVER_URL + `/exam-orders/${order_id}/download`,
		{
			headers: getHeader(),
		}
	);

	const url = result.data;

	if (!url) throw new Error("could not find url");

	return url;
};

export const getExamDownloadUrl = async (order_id, id) => {
	if (!order_id || !id) throw new Error("could not find order id or id");
	const result = await axios.get(
		process.env.REACT_APP_SERVER_URL +
			`/exam-orders/${order_id}/exams/${id}/download`,
		{
			headers: getHeader(),
		}
	);

	const url = result.data;

	if (!url) throw new Error("could not find url");

	return url;
};
