import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

export function ToastyError(props) {

    return(
        <Row>
            <Col xs={6}>
                <ToastContainer className="p-3" position='top-center'>
                    <Toast className='error-toast' show={props.show} bg='light'>
                        <Toast.Body>{props.errorText}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </Col>
        </Row>
    );
};

export function ToastySuccess(props) {

    return(
        <Row>
            <Col xs={6}>
                <ToastContainer className="p-3" position='top-center'>
                    <Toast className='success-toast' show={props.show} bg='light'>
                        <Toast.Body>{props.errorText}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </Col>
        </Row>
    );
};