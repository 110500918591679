import { useReducer, createContext, useEffect } from "react";
import { login, storeUserData } from "../services/userservice";

const initialState = {
  token: null,
  user: null,
  permissions: null,
};

const defaultValue = {
  state: initialState,
};

export const UserContext = createContext(defaultValue);

export const UserProvider = ({ children }) => {
  useEffect(() => {
    const userDataStr = localStorage.getItem("user");
    const userData = JSON.parse(userDataStr);
    const token = userData?.token;
    if (token) {
      dispatch({
        type: "STORE_USER_DATA",
        payload: userData,
      });
    }
  }, []);

  function LoginReducer(state, action) {
    switch (action.type) {
      case "STORE_USER_DATA":
        return {
          ...state,
          token: action.payload.token,
          user: action.payload.patient,
          permissions: action.payload.permissions,
        };

      case "CLEAR_USER_DATA": {
        localStorage.removeItem("user");
        return {
          token: null,
          user: null,
          permissions: null,
        };
      }

      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(LoginReducer, initialState);

  async function performLogin(user, password, accessType) {
    try {
      const userData = await login(user, password, accessType);

      if (!userData.token || !userData.patient || !userData.permissions) {
        throw new Error("Error on login");
      }

      storeUserData(JSON.stringify(userData));

      dispatch({
        type: "STORE_USER_DATA",
        payload: userData,
      });
    } catch (e) {
      storeUserData(null);
      dispatch({
        type: "CLEAR_USER_DATA",
      });
      throw e;
    }
  }

  function performLogout() {
    console.log("caiuaqq");
    dispatch({
      type: "CLEAR_USER_DATA",
    });
  }

  return (
    <UserContext.Provider
      value={{ state, dispatch, performLogin, performLogout }}
    >
      {children}
    </UserContext.Provider>
  );
};
