import React, { useContext, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "reactstrap";
import "../assets/sass/ForgetPassword.scss";
import { UserContext } from "../context/UserContext";
import { ToastyError, ToastySuccess } from "../helpers/alerts";
import { changePasswordRequest } from "../services/userservice";

function RenewPassword() {
	const { performLogin } = useContext(UserContext);

	const navigate = useNavigate();
	const [user, setUser] = useState({
		password: "",
		passwordConfirm: "",
	});

	const [isSubmitting, setSubmitting] = useState(false);

	const { token } = useParams();

	const fieldsFilled = fieldsAreFilled();

	// Variaveis do Toast
	const [show, setShow] = useState(false);
	const [errorText, setErrorText] = useState("");
	const [success, setSuccess] = useState(false);
	const [successText, setSuccessText] = useState('');

	function fieldsAreFilled() {
		return (
			user.password &&
			user.password.length > 4 &&
			user.passwordConfirm &&
			user.passwordConfirm.length > 4
		);
	}

	function validateFields() {
		return user.password === user.passwordConfirm;
	}

	function handleChange(event) {
		const { name, value } = event.target;
		setUser((prevUser) => {
			return {
				...prevUser,
				[name]: value,
			};
		});
	}

	async function handleSubmit(event) {
		event.preventDefault();

		if (!validateFields()) {
			setShow(true);
			setTimeout(() => setShow(false), 5000);
			setErrorText("A senha e a confirmação de senha devem ser iguais.");
			return;
		}

		if (!token || !token.length) {
			setShow(true);
			setTimeout(() => setShow(false), 5000);
			setErrorText("Este link de recuperação de senha é inválido.");
		}

		try {
			setSubmitting(true);

			const result = await changePasswordRequest(user.password, token);

			console.log(result);

			setSuccess(true);
			setTimeout(() => setShow(false), 5000);
			setSuccessText("Sua senha foi alterada com sucesso!");

			if (result && result.username) {
				await performLogin(result.username, user.password, "general");
			} else {
				navigate({
					pathname: "login",
				});
			}

			setSubmitting(false);
		} catch (e) {
			setSubmitting(false);

			if (e && e.response && e.response.data) {
				const error = e.response.data.error;

				if (error) {
					return alert(error);
				}
			}

			setShow(true);
			setTimeout(() => setShow(false), 5000);
			setErrorText("Ocorreu um erro ao tentar alterar a sua senha.");
		}
	}

	return (
		<div className="login-page">
			<ToastyError show={show} errorText={errorText} />
			<ToastySuccess show={success} errorText={successText} />
			<div className="login-box">
				<div className="illustration-wrapper">
					<img
						id="photo"
						src="https://www.reshot.com/preview-assets/illustrations/SUBYW3HTFQ/secure-credit-card-SUBYW3HTFQ-w1600-07817.jpg"
						alt="Login"
					/>
				</div>
				<Form name="forget-password" id="forget-password">
					<div>
						<p className="form-title mb-4">Clínica Vittá</p>

						<h5>Processo de alteração de senha</h5>

						<p>Preencha os campos abaixo para alterar a sua senha</p>

						<Form.Group name="password" id="user">
							<Input
								name="password"
								type="password"
								placeholder="Digite sua nova senha"
								onChange={handleChange}
								value={user.password}
							/>
						</Form.Group>

						<Form.Group name="passwordConfirm" id="user">
							<Input
								name="passwordConfirm"
								type="password"
								placeholder="Confirmar sua nova senha"
								onChange={handleChange}
								value={user.passwordConfirm}
							/>
						</Form.Group>

						{isSubmitting ? (
							<div style={{ textAlign: "center" }}>
								<Spinner animation="border" variant="info" style={{position:"absolute",top:"50%",left:"50%"}} />
							</div>
						) : (
							<div name="change" valuePropName="checked" id="change">
								<Button
									disabled={!fieldsFilled}
									onClick={handleSubmit}
									type="submit"
									id="button-change"
								>
									Alterar Senha
								</Button>
							</div>
						)}
					</div>
				</Form>
			</div>
		</div>
	);
}

export default RenewPassword;
