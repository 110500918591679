import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useSearchParams } from "react-router-dom";
import { Form, Input, Spinner } from "reactstrap";
import "../assets/sass/Login.scss";
import { UserContext } from "../context/UserContext";
import { InvalidCredentialsError } from "../errors/InvalidCredentialsError";
import { ToastyError } from "../helpers/alerts";

function LoginExam() {
	const { performLogin } = useContext(UserContext);

	const [searchParams] = useSearchParams();

	const userParam = searchParams.get("u");
	const passwordParam = searchParams.get("p");

	// Variaveis do Toast
	const [show, setShow] = useState(false);
	const [errorText, setErrorText] = useState("");

	useEffect(() => {
		submitAutomatically();
	}, []);

	async function submitAutomatically() {
		if (
			userParam &&
			userParam.length &&
			passwordParam &&
			passwordParam.length
		) {
			await handleSubmit(userParam, passwordParam);
		}
	}

	const [user, setUser] = useState({
		user: userParam ?? "",
		password: passwordParam ?? "",
	});

	const [isSubmitting, setSubmitting] = useState(false);

	function toggleSubmitting() {
		setSubmitting(!isSubmitting);
	}

	function handleChange(event) {
		const { name, value } = event.target;
		setUser((prevUser) => {
			return {
				...prevUser,
				[name]: value,
			};
		});
	}

	async function handleSubmit(username, password) {
		toggleSubmitting();

		try {
			await performLogin(username, password, "exams");

			// mostrar uma mensagem de sucesso
			toggleSubmitting();
		} catch (e) {
			setSubmitting(false);

			if (e instanceof InvalidCredentialsError) {
				setErrorText("As suas credenciais estão inválidas.");
			} else {
				setErrorText("Ocorreu um erro inesperado ao tentar autenticar.");
			}

			setShow(true);
			setTimeout(() => setShow(false), 5000);
		}
	}

	return (
		<div className="login-page">
			<ToastyError show={show} errorText={errorText} />
			<div className="login-box">
				<div className="illustration-wrapper">
					<img
						height={300}
						// width={500}
						id="photo"
						src="/assets/clinica-vitta-login.png"
						alt="Login"
					/>
				</div>
				<Form name="login-form" id="login-form">
					<div>
						<p className="form-title">Clínica Vittá</p>
						<p>Faça login para acessar seus resultados de exames</p>

						<div type="text" name="user" id="user">
							<Input
								type="text"
								name="user"
								placeholder="Código de Acesso"
								onChange={handleChange}
								value={user.user}
							/>
						</div>

						<div type="password" name="password" id="password">
							<Input
								type="password"
								name="password"
								placeholder="Senha"
								onChange={handleChange}
								value={user.password}
							/>
						</div>

						<div name="password" valuePropName="checked" id="remember">
							{isSubmitting ? (
								<Spinner variant="info" style={{position:"absolute",top:"50%",left:"50%"}} />
							) : (
								<Button
									disabled={!(user.password.length > 0 && user.user.length > 0)}
									onClick={() => handleSubmit(user.user, user.password)}
									type="submit"
									id="button-login"
								>
									Entrar
								</Button>
							)}
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
}

export default LoginExam;
