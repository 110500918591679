import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  border-radius: 6px;
  border-left: 10px solid ${(props) => props.color};
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  gap: 50px;
  max-width: 100%;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  margin-bottom: 8px;
  width: 75%;
`;
export const InfoFinalContainer = styled.div`
  display: flex;
  gap: 10px;
  max-width: 100%;
  color: #b9b9b9;
  font-weight: bold;
`;

export const Title = styled.h4`
  margin: 0;
  color: ${(props) => props.color};
`;

export const Information = styled.p`
  margin: 1px 0;
  color: #4d4d4d;
  font-weight: bold;
`;
export const SubInformation = styled.p`
  margin: 7px 0 0 1px;
  font-size: 12px;
  font-weight: bold;
  color: #afafaf;
`;

export const AdditionalInfo = styled.div``;

export const VerticalLine = styled.div`
  width: 3px;
  background-color: #ebebeb;
`;
