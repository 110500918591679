import React, { useContext } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { BsPhone, BsXCircle, BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { HiMail, HiUser } from "react-icons/hi";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserContext } from "../context/UserContext";
import "./NavBar.css";

function NavBar() {
  const { state, performLogout } = useContext(UserContext);
  function converterParaCamelCase(string) {
    try {
      let words = string.toLowerCase().split(" ");
      let camelCaseName = "";

      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        camelCaseName += word.charAt(0).toUpperCase() + word.slice(1);
        if (i !== words.length - 1) {
          camelCaseName += " ";
        }
      }

      return camelCaseName;
    } catch (e) {
      console.log(e);
      return "";
    }
  }
  return (
    <Navbar style={{ padding: "12px", height: "80px" }}>
      <Navbar.Brand className="navbar-brand">
        {/* <img
					alt="logo"
					src="/assets/logo-clinica-vitta.png"
					width="200px"
				></img> */}
        <img
          className="clinica-logo"
          alt="logo"
          src="https://clinicavitta.net/static/media/logo-light.9dc7403a.png"
          width="160px"
        ></img>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        style={{ marginLeft: "20px" }}
      >
        <Nav className="me-auto" style={{position:"relative",top:"5px"}}>
          <h5>
            <HiUser
              size={25}
              color="white"
              style={{ position: "relative", top: "-5px", fontWeight: "bold" }}
            />{" "}
            {converterParaCamelCase(state?.user?.name)
              ? converterParaCamelCase(state?.user?.name)
              : state?.user?.name}
          </h5>
        </Nav>
        <Nav id="talk-with-us">
          <div href="#deets" className="m-2">
            <div>Contato </div>
            <div>
              <BsPhone /> <strong>(62) 3996-0505</strong>
            </div>
          </div>
        </Nav>
        <Nav>
          <Link
            className="navbar-logout"
            to="/exames"
            onClick={performLogout}
            style={{ fontWeight: "bold" }}
          >
            <div>
              <BsArrowLeft fontSize={20} />
            </div>
            Sair
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
