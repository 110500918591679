import { Form, Spinner, Table, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EXAMS_ORDERS_STATUS from "../../../../utils/exams-orders-status";
import { format, parseISO } from "date-fns";
import { BsEyeFill } from "react-icons/bs";

const ExamListComponent = ({ examOrdersData, loadExamsInfo }) => {
  const navigate = useNavigate();
  return (
    <Table bordered responsive id="all-exams">
      <thead>
        <tr>
          {/* <th className="d-block d-sm-none">Detalhes</th> */}
          <th>Pedido</th>
          <th>Data do Pedido</th>
          {/* <th>Médico</th> */}
          <th>Unidade</th>
          <th>Exames</th>
          <th>Situação</th>
          <th className="">Detalhes</th>
        </tr>
      </thead>
      <tbody>
        {examOrdersData.data.map((examOrder, index) => (
          <tr
          key={"tr"+index}
            style={{ cursor: "pointer" }}
            data-index={index}
            onClick={() =>
              navigate(`/pedidos/${examOrder.id}/detalhes`, {
                state: { examDetails: examOrder },
              })
            }
          >
            <td>{examOrder.id}</td>
            <td>{format(parseISO(examOrder.created_at), "dd/MM/yyyy")}</td>
            {/* <td>{examOrder?.items[0]?.professional?.name}</td> */}
            <td>{examOrder.unit.name}</td>

            <td>
              {loadExamsInfo(examOrder.items,examOrder?.status)}
              {/* {examOrder.items.map((dt) => {
              reportNames = dt.item.name + "; ";

              return reportNames;
            })} */}
            </td>
            
            <td>
              {" "}
              <Badge
                className={
                  examOrder?.status == EXAMS_ORDERS_STATUS.IN_PROGRESS
                    ? "bg-primary"
                    : "bg-success"
                }
              >
                {examOrder?.status_name}
              </Badge>
            </td>
            <td className="text-center">
              {/* <Button
                                  onClick={() =>
                                      navigate(`/pedidos/${examOrder.id}/detalhes`, {
                                          state: { examDetails: examOrder },
                                      })
                                  }
                              >
                                  {" "}
                                  <BsEyeFill />{" "}
                              </Button> */}
              <BsEyeFill
                size={25}
                style={{ cursor: "pointer", color:  examOrder?.status == EXAMS_ORDERS_STATUS.IN_PROGRESS ? "#0d6efd" : "green" }}
              />{" "}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ExamListComponent;
