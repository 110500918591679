import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import ExamDetails from "../views/ExamsOrders/ExamDetails";
import ExamList from "../views/ExamsOrders/ExamList";
import ForgetPassword from "../views/ForgetPassword";
import ForgetPasswordEmail from "../views/ForgetPasswordEmail";
import ForgetPasswordError from "../views/ForgetPasswordError";
import Login from "../views/Login";
import LoginExam from "../views/LoginExam";
import RenewPassword from "../views/RenewPassword";

function AllRoutes() {
	const { state } = useContext(UserContext);
	
	const isAuthenticated = state.token;

	if (!isAuthenticated)
		return (
			<Router>
				<Routes>
					<Route path="*" element={<LoginExam />} />
					<Route path="/login" element={<Login />} />
					<Route path="/exames" element={<LoginExam />} />
					<Route path="/forgetPassword" element={<ForgetPassword />} />
					<Route path="/recover-password/:token" element={<RenewPassword />} />
					<Route
						path="forgetPassword/email"
						element={<ForgetPasswordEmail />}
					/>
					<Route
						path="forgetPassword/error"
						element={<ForgetPasswordError />}
					/>
				</Routes>
			</Router>
		);

	return (
		<Router>
			<Routes>
				<Route path="*" element={<ExamList />} />
				<Route path="/pedidos" element={<ExamList />} />
				<Route path="/pedidos/:id/detalhes" element={<ExamDetails />} />
			</Routes>
		</Router>
	);
}

export default AllRoutes;
