import React from "react";
import { Nav } from "react-bootstrap";
import { BsCardList } from "react-icons/bs";
import { BsJournalMedical } from "react-icons/bs";
import { Link } from "react-router-dom";

import "../assets/sass/SideBar.scss";

const SideBar = (props) => {
  return (
    <>
      <Nav className="col-md-12 d-none d-md-block  sidebar">
        <div className="sidebar-sticky"></div>
        <Nav.Item>
          <Nav.Link>
            <Link className="sidebar-item" to="/pedidos">
              <BsJournalMedical
                size={20}
                style={{ position: "relative", top: "-4px" }}
              />{" "}
              <span>Pedidos de Exames</span>
            </Link>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default SideBar;
