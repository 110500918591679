import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { Input, Spinner } from "reactstrap";
import "../assets/sass/Login.scss";
import { UserContext } from "../context/UserContext";
import { InvalidCredentialsError } from "../errors/InvalidCredentialsError";
import { ToastyError } from "../helpers/alerts";

function Login() {
	const { performLogin } = useContext(UserContext);
	const navigate = useNavigate();

	// Variaveis do Toast
	const [show, setShow] = useState(false);
	const [errorText, setErrorText] = useState("");

	const [user, setUser] = useState({
		user: "",
		password: "",
	});

	const [isSubmitting, setSubmitting] = useState(false);

	function handleChange(event) {
		const { name, value } = event.target;
		setUser((prevUser) => {
			return {
				...prevUser,
				[name]: value,
			};
		});
	}

	function validateFields() {
		return true;
	}

	async function handleSubmit(event) {
		event.preventDefault();

		// validações
		const isValid = validateFields();

		if (isValid) {
			setSubmitting(true);

			try {
				await performLogin(user.user, user.password, "general");

				navigate("/pedidos");
				// mostrar uma mensagem de sucesso
				setSubmitting(false);
			} catch (e) {
				setSubmitting(false);

				if (e instanceof InvalidCredentialsError) {
					setErrorText("As suas credenciais estão inválidas.");
				} else {
					setErrorText("Ocorreu um erro inesperado ao tentar autenticar.");
				}

				setShow(true);
				setTimeout(() => setShow(false), 5000);
			}
		}
	}

	return (
		<div className="login-page">
			<ToastyError className="toast-error" show={show} errorText={errorText} />
			<div className="login-box">
				<div className="illustration-wrapper">
					<img
						height={300}						
						src="/assets/clinica-vitta-login.png"
						alt="Login"
					/>
				</div>
				<form name="login-form" id="login-form">
					<div>
						<p className="form-title">Clínica Vittá</p>
						<p>Faça login para acessar seus resultados de exames</p>

						<div name="user" id="user">
							<Input
								type="text"
								name="user"
								placeholder="Usuário (CPF)"
								onChange={handleChange}
								value={user.user}
							/>
						</div>

						<div name="password" id="password">
							<Input
								type="password"
								name="password"
								placeholder="Senha"
								onChange={handleChange}
								value={user.password}
							/>
						</div>

						<div style={{ textAlign: "center" }}>
							{isSubmitting ? (
								<Spinner variant="info" style={{position:"absolute",top:"50%",left:"50%"}} />
							) : (
								<Button
									disabled={!(user.password.length > 0 && user.user.length > 0)}
									onClick={handleSubmit}
									type="submit"
									id="button-login"
								>
									Entrar
								</Button>
							)}
						</div>
					</div>

					<div
						style={{
							display: "flex",
							flex: 1,
							marginTop: 30,
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Link to="/forgetPassword" id="forget">
							Esqueceu sua senha ?
						</Link>
					</div>
				</form>
			</div>
		</div>
	);
}

export default Login;
