import "bootstrap/dist/css/bootstrap.min.css";
import { format, parseISO } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { Form, Spinner, Table, Badge } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "../../assets/sass/ExamList.scss";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { UserContext } from "../../context/UserContext";
import { ForbiddenError } from "../../errors/ForbiddenError";
import { ToastyError } from "../../helpers/alerts";
import { fetchExamOrders } from "../../services/examsorderservice";
import EXAMS_ORDERS_STATUS from "../../utils/exams-orders-status";
import ExamListComponent from "./Components/ExamListComponents/ExamListComponent";
import ExamListComponentMobile from "./Components/ExamListComponents/ExamListComponentMobile";
function ExamList() {
  const navigate = useNavigate();
  const { performLogout } = useContext(UserContext);

  // Variaveis do Toast
  const [show, setShow] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    fetchExams();
  }, []);

  const [examOrdersData, setExamOrdersData] = useState({
    current_page: null,
    data: [],
    total_pages: null,
    total_items: null,
  });

  const [isFetching, setFetching] = useState(true);

  let pagesArr = [];

  for (let i = 1; i <= examOrdersData.total_pages; i++) {
    pagesArr.push(i);
  }

  // Pesquisa
  const [searchData, setSearchData] = useState({
    start_date: null,
    end_date: null,
  });

  var reportNames = "";

  async function fetchExams(page = 1) {
    try {
      setFetching(true);

      const filters = {
        start_date: searchData.start_date,
        end_date: searchData.end_date,
        page,
      };

      const result = await fetchExamOrders(filters);

      setFetching(false);

      setExamOrdersData(result);
    } catch (e) {
      setFetching(false);
      setShow(true);
      setTimeout(() => setShow(false), 5000);
      setErrorText("Ocorreu um erro ao tentar buscar os pedidos.");
      if (e instanceof ForbiddenError) {
        performLogout();
      }
    }
  }

  function onChangeStartDate(e) {
    setSearchData({
      ...searchData,
      start_date: e.target.value,
    });
  }

  function onChangeEndDate(e) {
    setSearchData({
      ...searchData,
      end_date: e.target.value,
    });
  }

  function paginate(page) {
    fetchExams(page);
  }

  function OrdersTable() {
    if (!examOrdersData.data.length && !isFetching) {
      return (
        <div
          style={{
            marginTop: 12,
            marginBottom: 10,
            textAlign: "center",
            color: "#0194d4",
            fontWeight: "bold",
          }}
        >
          <span>Nenhum pedido de exame foi encontrado neste período...</span>
        </div>
      );
    }
    if (isFetching) {
      return (
        <div style={{ textAlign: "center", margin: 25 }}>
          <Spinner
            animation="border"
            variant="primary"
            style={{ position: "absolute", top: "50%", left: "50%" }}
          />
        </div>
      );
    }
    const loadExamsInfo = (exams, status) => {
      let html = <span>Sem exames</span>;
      if (!exams || exams?.length <= 0) return html;
      if (exams?.length > 1) {
        html = (
          <div>
            {exams[0]?.item?.name?.replace("- EXAMES LABORATORIAIS", "")}{" "}
            <Badge
              style={{ fontSize: "11px", position: "relative", top: "-1px" }}
              className={
                status == EXAMS_ORDERS_STATUS.IN_PROGRESS
                  ? "badge-exams-primary"
                  : "badge-exams-success"
              }
              pill
            >
              <span style={{ position: "relative", top: "-1px" }}>
                + {exams?.length - 1}
              </span>
            </Badge>
          </div>
        );
      } else {
        html = (
          <div>
            {exams[0]?.item?.name?.replace("- EXAMES LABORATORIAIS", "")}{" "}
          </div>
        );
      }

      return html;
    };
    return (
      <>
        <ToastyError show={show} errorText={errorText} />
        <div className="above-mobile">
          <ExamListComponent
            examOrdersData={examOrdersData}
            loadExamsInfo={loadExamsInfo}
          />
        </div>
        <div className="show-mobile">
          <ExamListComponentMobile
            examOrdersData={examOrdersData}
            loadExamsInfo={loadExamsInfo}
          />
        </div>

        <nav style={{ marginTop: 15 }}>
          <ul className="pagination above-mobile">
            {pagesArr.map((number) => (
              <li
                key={number}
                className={
                  number == examOrdersData.current_page
                    ? "page-item selected"
                    : "page-item"
                }
              >
                <a
                  onClick={() => paginate(number)}
                  href="#"
                  className="page-link"
                >
                  {number}
                </a>
              </li>
            ))}
          </ul>
          {examOrdersData?.total_pages > examOrdersData?.current_page ? (
            <div
              onClick={() => {
                paginate(examOrdersData.current_page + 1);
              }}
              className="show-mobile mt-4 mb-3"
              align="center"
            >
              <Button variant="primary" style={{ width: "60%" }}>
                Buscar mais pedidos
              </Button>
            </div>
          ) : (
            ""
          )}
        </nav>
      </>
    );
  }

  return (
    <>
      <NavBar></NavBar>
      <ToastyError show={show} errorText={errorText} />
      <SideBar></SideBar>
      <div className="user-page">
        <div id="exam-list">
          <h5>Pedidos de Exames</h5>
          <br />

          <div className="d-flex search-items">
            <Form.Group className="d-flex mb-3 sm-8" controlId="startDate">
              <div style={{ flex: 1 }}>
                <Form.Label>Data inicial</Form.Label>
                <Form.Control
                  name="startDate"
                  type="date"
                  onChange={onChangeStartDate}
                  value={searchData.start_date}
                />
              </div>
            </Form.Group>

            <Form.Group
              id="final-date"
              className="d-flex mb-3 sm-8"
              controlId="finalDate"
            >
              <div style={{ flex: 1 }}>
                <Form.Label>Data final</Form.Label>
                <Form.Control
                  name="finalDate"
                  type="date"
                  onChange={onChangeEndDate}
                  value={searchData.end_date}
                />
              </div>
            </Form.Group>

            <div id="button-search">
              <Button
                onClick={() => fetchExams(1)}
                variant="outline-primary"
                id="button-addon2"
              >
                Pesquisar
              </Button>
            </div>
          </div>

          <OrdersTable />
        </div>
      </div>
    </>
  );
}

export default ExamList;
