import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsArrowReturnLeft } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import { ToastyError } from "../helpers/alerts";
import { forgotPasswordRequest } from "../services/userservice";

import "../assets/sass/ForgetPassword.scss";

function ForgetPassword() {
	const [isSubmitting, setSubmitting] = useState(false);

	const [user, setUser] = useState({
		CPF: "",
	});

	const navigate = useNavigate();

	// Variaveis do Toast
	const [show, setShow] = useState(false);
	const [errorText, setErrorText] = useState("");

	function handleChange(event) {
		const { name, value } = event.target;
		setUser((prevUser) => {
			return {
				...prevUser,
				[name]: value,
			};
		});
	}

	async function handleSubmit(event) {
		event.preventDefault();

		const validarCPF = user.CPF.match(
			/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/g
		);

		if (!validarCPF) {
			setShow(true);
			setTimeout(() => setShow(false), 5000);
			setErrorText("Digite um CPF válido.");
			return;
		}

		try {
			setSubmitting(true);

			await forgotPasswordRequest(user.CPF);

			navigate("/forgetPassword/email");

			setSubmitting(false);
		} catch (e) {
			console.error(e);
			setSubmitting(false);
			navigate("/forgetPassword/error");
		}
	}

	return (
		<div className="login-page">
			<ToastyError show={show} errorText={errorText} />
			<div className="login-box">
				<div className="back-button-container">
					<Link id="arrow-back" to="/">
						{/* {" "} */}
						<Button variant="secundary">
							<BsArrowReturnLeft />{" "}
						</Button>
					</Link>
				</div>
				<div className="illustration-wrapper">
					<img
						id="photo"
						src="https://www.reshot.com/preview-assets/illustrations/SUBYW3HTFQ/secure-credit-card-SUBYW3HTFQ-w1600-07817.jpg"
						alt="Login"
					/>
				</div>

				<Form
					name="forget-password"
					id="forget-password"
					onSubmit={handleSubmit}
				>
					<div>
						<p className="form-title">Clínica Vittá</p>
						<p>
							Digite seu CPF para receber um e-mail de recuperação de senha.
						</p>

						<Form.Group name="CPF" id="user">
							<Input
								name="CPF"
								placeholder="Digite seu CPF"
								onChange={handleChange}
								value={user.CPF}
								maxLength={11}
							/>
						</Form.Group>

						{isSubmitting ? (
							<div style={{ textAlign: "center" }}>
								<Spinner animation="border" variant="info" style={{position:"absolute",top:"50%",left:"50%"}} />
							</div>
						) : (
							<div style={{ textAlign: "center" }}>
								<Button
									type="submit"
									disabled={user.CPF.length < 11}
									id="button-change"
								>
									Recuperar Senha
								</Button>
							</div>
						)}
					</div>
				</Form>
			</div>
		</div>
	);
}

export default ForgetPassword;
