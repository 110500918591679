import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  border-radius: 6px;
  border-left: 10px solid ${(props) => props.color};
  padding: 10px;
  position: relative;
  gap: 50px;
  max-width: 100%;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  margin-bottom: 8px;
  width: 75%;
`;
export const InfoFinalContainer = styled.div`
  display: flex;
  gap: 10px;
  max-width: 100%;
  color: #b9b9b9;
  font-weight: bold;
`;

export const Title = styled.h4`
  margin: 0;
  color: ${(props) => props.color};
`;

export const Information = styled.p`
  margin: 1px 0;
  color: #4d4d4d;
  font-weight: bold;
`;
export const SubInformation = styled.p`
  margin: 7px 0 0 1px;
  font-size: 12px;
  font-weight: bold;
  color: #afafaf;
`;

export const TitleExamsResult = styled.h5`
  font-weight: bold;
  margin-top: 20px;
  color: ${(props) => props.color};
`;

export const AccordionContainer = styled.div`
  width: 100%;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f2f2f2;
  cursor: pointer;
  border-radius: 5px;
  border-bottom: 1px solid #ccc;
`;

export const CardContent = styled.div`
  padding: 10px;
  background-color: #f2f2f2;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const TableLabel = styled.div`

  margin-right: 10px;
`;
export const DownloadButton = styled.button`
  margin-top: 35px;
  margin-bottom:35px;
  background-color: ${(props) => props.color};
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  &:hover {
    background-color: ${(props) => props.color};
  }
`;
