import React from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { BsArrowReturnLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

import "../assets/sass/ForgetPassword.scss";

function ForgetPasswordError() {
	return (
		<div className="login-page">
			<div className="login-box">
				<div className="illustration-wrapper">
					<img
						id="photo"
						src="https://www.reshot.com/preview-assets/illustrations/SUBYW3HTFQ/secure-credit-card-SUBYW3HTFQ-w1600-07817.jpg"
						alt="Login"
					/>
				</div>

				<Form name="forget-password" id="forget-password">
					<div>
						<p className="form-title">
							Clínica Vittá
							<Link id="arrow-back" to="/forgetPassword">
								{/* {" "} */}
								<Button variant="secundary">
									<BsArrowReturnLeft />{" "}
								</Button>
							</Link>
						</p>
						<div className="mt-3">
							<small>
								O CPF digitado não possui cadastro ou não é válido. Faça o seu
								cadastro ou confira seus dados e tente novamente.
							</small>
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
}

export default ForgetPasswordError;
